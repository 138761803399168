<template>
  <section class="pt-5 pb-5 px-2">
    <b-card 
      v-for="(blog, i) in blogs" :key="i" md="12"
      class="overflow-hidden rounded-lg"
    >
      <b-row>
        <b-col cols="12" md="3">
          <b-card-img 
            :src="getValueFromSource(blog, 'featured_image.path', 'https://picsum.photos/400/400/?image=20')"
            img-height="300px"
          ></b-card-img>
        </b-col>

        <b-col cols="12" md="9">
          <div class="">
            <div>
              <h1 class="blog-title-">
                {{ getValueFromSource(blog, 'title') }}
              </h1>
            </div>
            <div>
              <p class="blog-content-" style="line-height: 14px">
                {{ extractTextContentFromHTML(getValueFromSource(blog, 'content')) }}
              </p>
              <div>
                <router-link :to="{ name: 'single-blog', params: { slug: getValueFromSource(blog, 'slug')} }">
                  <h3 class="text-secondary">
                    Read More ->
                  </h3>
                </router-link>
              </div>
            </div>
          </div>
        </b-col>
      </b-row>
    </b-card>

    <div v-if="!blogs.length && !loading">
      <h3 class="my-5">
        No content available.
      </h3>
    </div>

    <div class="d-flex justify-content-end align-items-center">
      <!-- pagination -->
      <div class="my-2">
       <b-pagination v-model="currentPage" :total-rows="totalRecords" :per-page="perPage" first-number last-number prev-class="prev-item" next-class="next-item">
         <template #prev-text>
           <feather-icon icon="ChevronLeftIcon" size="18" />
         </template>
         <template #next-text>
           <feather-icon icon="ChevronRightIcon" size="18" />
         </template>
       </b-pagination>
      </div>
    </div>
  </section>
</template>

<script>
import {
  BCol,
  BRow,
  BCard,
  BBadge,
  BCardImg,
  BImg,
  BOverlay,
  BPagination
} from "bootstrap-vue";
import { get } from "lodash";
import Search from '@/assets/svg/Search.svg';
import { kFormatter } from "@core/utils/filter";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";

const watchHandler = {
  handler() {
    this.fetchAllData()
  },
  immediate: false
}

const watchHandlerRequiresPageReset = {
  handler(){
    this.currentPage = 1;
    this.fetchAllData()
  },
  immediate: false
}


export default {
  name: "ListBlogs",
  components: {
    BOverlay,
    BRow,
    BCard,
    BCol,
    BBadge,
    BCardImg,
    BImg,
    Search,
    BPagination
  },
  data() {
    return {
      loading: true,
      blogs: [],

      debounceFetchAllData: null
    }
  },
  computed: {
    settings(){
      return this.$store.getters[`auth/settings`];
    },
    perPage: {
      get() {
        return this.$store.getters[`navigation/recordsPerPage`];
      },
      set(value){
        this.$store.commit(`navigation/UPDATE_RECORDS_PER_PAGE`, value);
      }
    },
    currentPage: {
      get() {
        return this.$store.getters[`navigation/currentPage`];
      },
      set(value){
        this.$store.commit(`navigation/UPDATE_CURRENT_PAGE_NUMBER`, value)
      }
    },
    totalRecords: {
      get(){
        return this.$store.getters[`navigation/totalRecords`];
      },
      set(value){
        this.$store.commit(`navigation/UPDATE_TOTAL_RECORDS`, value)
      }
    },
  },

  watch: {
    perPage: watchHandlerRequiresPageReset,
    currentPage: watchHandler,
  },
  created() {
    this.fetchAllData();

    const {
      search = "",
      page = 1,
      limit = this.perPage
    } = this.$route.query;

    this.perPage = +limit;
    this.searchFilter = search;
    this.currentPage = +page;
  },
  methods: {
    async fetchAllData() {
      try {
        this.loading = true;

        const query = {
          limit: this.perPage,
          page: this.currentPage,
          search: this.searchFilter,
        }

        this.$router.push({ query }).catch(() => {});

        const request = await this.useJwt().sharedService.fetchBlogPosts(query);
        const { data, pagination } = request.data;
        this.blogs = data;

        this.totalRecords = pagination.totalRecords
        this.perPage = pagination.limit;
      } catch (error) {
        const error_message = get(error, "response.data.message") || error.message
        console.log("error_message", error_message)
        this.$toast({
          component: ToastificationContent,
          props: {
            title: 'Error!',
            icon: 'AlertTriangleIcon',
            variant: 'danger',
            text: error_message
          },
        });
      } finally {
        this.loading = false;
      }
    },
    kFormatter,
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/pages/page-blog.scss';

.blog-title- {
  display: -webkit-box;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
  overflow: hidden;
}

.blog-content- {
  display: -webkit-box;
  -webkit-line-clamp: 5;
  -webkit-box-orient: vertical;
  overflow: hidden;
  font-size: 13px;
}
</style>
